import { FormElement, FormStructureScheme, StepSchema } from "@energylab/shared-components/components/UI/form/formBuilderManager/types";
import { registerStructure as defaultRegisterStructure } from "@energylab/shared-components/modules/register/registerForm/config";

const replaceFormElement = (formElements: FormElement[], newFormElement: FormElement, property: string) => {
    const formElementIdx = formElements.findIndex(el => el.property === property)
    if (formElementIdx !== -1) {
        formElements[formElementIdx] = newFormElement
    }
}

const {steps} = defaultRegisterStructure as StepSchema
const firstStep = steps![0]
const newFormElements = [...firstStep.formElements]

const visibleCompanyCodeFormEl: FormElement = {
    title: "form.companyCode",
    type: "text",
    property: "companyCode",
    required: true
}

const visibleCompanyCodeIntroFormEl: FormElement = {
    title: "form.companyCodeIntro",
    type: "p",
    property: "companyCodeIntro",
}

replaceFormElement(newFormElements, visibleCompanyCodeFormEl, "companyCode")
replaceFormElement(newFormElements, visibleCompanyCodeIntroFormEl, "companyCodeIntro")

firstStep.formElements = newFormElements
const updatedSteps = [firstStep, ...steps!.slice(1)]

const stepsSchema: StepSchema = {
    type: "steps",
    steps: updatedSteps
}

export const registerStructure: FormStructureScheme = {
    ...stepsSchema
}