import { generateNewTheme } from "@energylab/shared-components/style/theme";

// TODO: Change primary color
const primaryColor = "#522ab9";

export const theme = generateNewTheme({
    colors: {
        primary: primaryColor,
        primaryHover: "#877eb7",
        secondary: "#2d5c90",
        black: "#000000",
        white: "#FFFFFF",
        darkGrey: "#3b3b3b",
        mediumGrey: "#8e8e8e",
        subGrey: "#d4b5b5",
        lightGrey: "#ececec",
        error: "#b91919",
        warning: "#b17f18",
        success: "#2cb931",
        background: "#fbfbfb"
    },
    activityColors: {
        running: "#E30614",
        cycling: "#6E6E6E",
        other: "#4565A0",
        swimming: "#7caad6",
        walking: "#96b439"
    },
    breakpoints: {
        header: "1024px",
        footer: "800px",
    },
    fonts: {
        primary: "'Poppins', sans-serif",
        secondary: "\"Poppins\", sans-serif"
    },
    header: {
        defaultHeight: 80,
        mobileHeight: 80
    },
    footer: {
        defaultHeight: 60,
        mobileHeight: 160
    },
    widgets: {
        margin: 40,
    },
    innerContainer: {
        maxWidth: 1600,
        padding: 80
    },
    shadows: {
        bigShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
        smallShadow: "0 2px 4px rgba(0,0,0,0.06), 0 2px 4px rgba(0,0,0,0.10)",
        lineShadow: "0 0 0 1px rgba(0,0,0,0.08)",
        inputShadow: "none",
        insetShadow: "inset 0 1px 3px rgba(0, 0, 0, .25)",
        cardShadow: "0 10px 15px -12px rgba(0,0,0,.1)",
        cardHoverShadow: "0 10px 15px 0 rgba(0, 0, 0, .1)",
    },
    borders: {
        inputBorder: "1px solid #C2C2C2"
    },
    borderRadius: {
        smallBorderRadius: "4px",
        tagBorderRadius: "15px"
    }
});

// ANTD Less Vars Replacement
export const ANTD_THEME = {
    primaryColor,
//     'error-color': "#CF4948",
//     'layout-sider-background': "#282828",
//     'layout-header-background': "#FFFFFF",
//     'layout-header-height': "55px",
//     'layout-header-padding':"0 39px",
//     'menu-dark-color': "#BABABA",
//     'menu-dark-bg': '#282828',
//     'menu-dark-arrow-color': '#FFFFFF',
//     'menu-dark-submenu-bg': '#101010',
//     'menu-dark-highlight-color': '#FDFEFC',
//     'menu-dark-item-active-bg': primaryColor
// }
}